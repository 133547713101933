import React from 'react'

import { graphql, PageProps } from 'gatsby'
// import { board } from '../../components/board/board';
import Layout from '../../layouts/index'
import { SEO } from '../../components/seo'
import useGovernancePage from '../../utils/use-governance-page'
import { StarIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import { useMediaQuery } from 'react-responsive'

export default function Committees(props: PageProps) {
  //   const tabs = [
  //     { name: 'board', href: '/governance/board', current: true },
  //     { name: 'Board of Directors', href: '/governance/board', current: false },
  //     { name: 'Governance', href: '/governance/index', current: false },
  //   ];

  const committees = props.data.committees.edges
  const committeesPage = props.data.committeesPage
  const governancePage = useGovernancePage()
  
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  })

  const renderIcon = (value) => {
    switch (value) {
      case `Member`: {
        return (
          <>
            <StarIcon className="w-4 h-4" />
          </>
        )
      }

      case `Head`: {
        return (
          <>
            <StarIconSolid className="w-4 h-4" />
          </>
        )
      }

      default: {
        return ''
      }
    }
  }

  const committeesExist = '-my-2 overflow-x-auto md:-mx-6 lg:-mx-8'
  const noCommittees = '-my-2 overflow-x-auto pb-2 pt-2'

  return (
    <Layout
      location={props.location}
      heros={governancePage.hero}
      herosFallbackTitle={governancePage.title}
      herosFallbackSecondaryTitle="Committees"
      tabs="governance"
    >
      <div className="container flex flex-col mx-auto mt-8 mb-20 lg:mt-4">
        <div className={committees.length > 0 ? committeesExist : noCommittees}>
          {committees.length > 0 ? (
            <>
              {isMobile ? (
                <div className="mb-12">
                  <div className="flex items-center justify-start mb-2 text-xs">
                    <span className="flex items-center mr-2 text-gray-700 dark:text-gray-300">
                      <StarIcon className="w-4 h-4 mr-1 opacity-70" />
                      Member
                    </span>
                    <span className="flex items-center">
                      <StarIconSolid className="w-4 h-4 mr-1 opacity-70" />
                      Chairperson
                    </span>
                  </div>
                  {committees.map((result) => {
                    return (
                      <div
                        key={result.node.slug}
                        className="w-full my-3 px-6 py-3 bg-gray-100 dark:bg-gray-900"
                      >
                        <div className="flex py-2">
                          <span className="w-32 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                            Name
                          </span>
                          <div className="text-sm text-gray-900 dark:text-gray-200">
                            {result.node.name}
                          </div>
                        </div>
                        {result.node.audit && 
                          <div className="flex py-2">
                            <span className="w-32 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                              Audit
                            </span>
                              {renderIcon(result.node.audit)}
                          </div>
                        }
                        {result.node.compensation &&
                          <div className="flex py-2">
                            <span className="w-32 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                              Remuneration
                            </span>
                              {renderIcon(result.node.compensation)}
                          </div>
                        }
                        {result.node.nominating &&
                          <div className="flex py-2">
                            <span className="w-32 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                              Nomination
                            </span>
                              {renderIcon(result.node.nominating)}
                          </div>
                        }
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b border-gray-200 dark:border-gray-800 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-100 dark:bg-gray-800">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                          >
                            Audit
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                          >
                            Remuneration
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                          >
                            Nomination
                          </th>
                          {/* <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Edit</span>
                            </th> */}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {committees.map((result) => (
                          <tr key={result.node.slug}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex justify-start text-left text-gray-900 dark:text-gray-200">
                                {result.node.name}
                              </div>
                            </td>
                            <td className="px-6 py-4 border-l dark:border-gray-700 whitespace-nowrap">
                              <div className="flex items-center justify-center text-gray-900 dark:text-gray-200">
                                {renderIcon(result.node.audit)}
                              </div>
                            </td>
                            <td className="px-6 py-4 border-l dark:border-gray-700 whitespace-nowrap">
                              <div className="flex items-center justify-center text-gray-900 dark:text-gray-200">
                                {renderIcon(result.node.compensation)}
                              </div>
                            </td>
                            <td className="px-6 py-4 border-l dark:border-gray-700 whitespace-nowrap">
                              <div className="flex items-center justify-center text-gray-900 dark:text-gray-200">
                                {renderIcon(result.node.nominating)}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex items-center justify-start py-8 text-xs">
                    <span className="flex items-center mr-2 text-gray-700 dark:text-gray-300">
                      <StarIcon className="w-4 h-4 mr-1 opacity-70" />
                      Member
                    </span>
                    <span className="flex items-center">
                      <StarIconSolid className="w-4 h-4 mr-1 opacity-70" />
                      Chairperson
                    </span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {committeesPage.noResults ? (
                <div className=" items-center flex justify-center flex-col py-20 rounded bg-gray-100 dark:bg-gray-900 mx-auto text-lg text-black dark:text-white">
                  <UserGroupIcon className="w-12 h-12 text-gray-500" />
                  <h6 className="flex justify-center mx-auto text-md text-gray-500">
                    {committeesPage.noResults.noResults}
                  </h6>
                </div>
              ) : null}
            </>
          )}
        </div>
        <div>
          <div className="inline-block min-w-full py-2 align-middle text-sm prose dark:prose-dark">
            <div>
              <h6 className="text-gray-500 text-base italic">
                * Audit Committee
              </h6>
              <p className="text-gray-500 committees-description">
                The Audit Committee advises the Board in relation to its
                responsibilities, undertakes preparatory work for the Board’s
                decision-making regarding the supervision of the integrity and
                quality of the Company’s financial reporting and the
                effectiveness of the Company’s internal risk management and
                control systems and shall prepare resolutions of the Board in
                relation thereto.
              </p>
            </div>
            <div>
              <h6 className="text-gray-500 text-base italic">
                * Nomination Committee
              </h6>
              <p className="text-gray-500 committees-description">
                The Nomination Committee advises the Board in relation to its
                responsibilities and shall prepare resolutions of the Board in
                relation thereto.
              </p>
            </div>
            <div>
              <h6 className="text-gray-500 text-base italic">
                * Remuneration Committee
              </h6>
              <p className="text-gray-500 committees-description">
                The Remuneration Committee advises the Board in relation to its
                responsibilities and shall prepare resolutions of the Board in
                relation thereto.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    committeesPage: contentfulCustomPage(slug: { eq: "committees" }) {
      noResults {
        noResults
      }
    }
    committees: allContentfulPeople(
      filter: { type: { in: "Committee Member" } }
      sort: { order: [DESC, ASC], fields: [sticky, weight] }
    ) {
      edges {
        node {
          name
          title
          slug
          audit: committeeAudit
          compensation: commiteeCompensation
          executive: committeeExecutive
          finance: committeeFinance
          nominating: committeeNominating
        }
      }
    }
  }
`
